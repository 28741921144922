var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-2",attrs:{"flat":""}},[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","sm":"5"}},[_c('v-toolbar-title',{staticClass:"error--text text-uppercase font-weight-bold text--darken-1 justify-center"},[_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"color":"error"}},[_vm._v("mdi-shopping")]),_vm._v("Applied Tenders ")],1),_c('p',{staticClass:"grey--text ml-8 mt-n2"},[_vm._v("List of all applied tenders")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-divider')]},proxy:true},{key:"item.closing_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.closing_date)+" ")]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_vm._l((item.documents),function(item,i){return _c('a',{key:i,attrs:{"link":"","href":_vm.docURI}},[_vm._v("("+_vm._s(i + 1)+") "+_vm._s(item.name)+" , ")])})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status.slug == 'open')?_c('a',{staticClass:"success--text"},[_vm._v(" "+_vm._s(item.status.name)+" ")]):_c('a',{staticClass:"grey--text"},[_vm._v(_vm._s(item.status.name))])]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCompanyName(item.company))+" ")]}},{key:"item.tender_no",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"primary--text text--darken-1 text-decoration-underline",on:{"click":function($event){$event.stopPropagation();return _vm.tenderPreview(item)}}},[_vm._v(_vm._s(item.tender_no))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"depressed":"","color":"grey","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"depressed":"","color":"error","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}},{key:"item.bidders_count",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-auto",attrs:{"elevation":0,"dark":"","small":"","fab":"","color":"primary"}},[_c('span',{staticClass:"font-weight-bold button"},[_vm._v(" "+_vm._s(item.bidders_count))])])]}},{key:"item.clarifications_count",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-auto",attrs:{"elevation":0,"dark":"","small":"","rounded":"","color":"warning"},on:{"click":function($event){return _vm.clarificationPreview(item)}}},[_c('span',{staticClass:"font-weight-bold button"},[_vm._v(" "+_vm._s(item.clarifications_answered + "/" + item.clarifications_count))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }