<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="name"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="12" sm="5" class="mt-5">
            <v-toolbar-title
              class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
            >
              <v-icon class="mr-2 mt-n1" color="error"
                >mdi-shopping</v-icon
              >Applied Tenders
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">List of all applied tenders</p>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-col>
        </v-toolbar>
                 <v-progress-linear
                  indeterminate
                  color="primary"
                  v-if="loading"
                ></v-progress-linear>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.closing_date`]="{ item }">
        {{ item.closing_date }}
      </template>
      <template v-slot:[`item.documents`]="{ item }">
        {{ item.name }}
        <a
          v-for="(item, i) in item.documents"
          :key="i"
          link
          v-bind:href="docURI"
          >({{ i + 1 }}) {{ item.name }} ,
        </a>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <a v-if="item.status.slug == 'open'" class="success--text">
          {{ item.status.name }}
        </a>
        <a v-else class="grey--text">{{ item.status.name }}</a>
      </template>
      <template v-slot:[`item.company`]="{ item }">
        {{ getCompanyName(item.company) }}
      </template>
      <template v-slot:[`item.tender_no`]="{ item }">
        <a
          @click.stop="tenderPreview(item)"
          class="primary--text text--darken-1 text-decoration-underline"
          >{{ item.tender_no }}</a
        >
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row align="center" justify="space-around">
          <v-btn depressed color="grey" fab small dark @click="editItem(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>

          <v-btn
            depressed
            color="error"
            fab
            small
            dark
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.bidders_count`]="{ item }">
        <v-btn
          class="mx-auto"
          :elevation="0"
          dark
          small
          fab
          color="primary"
        >
          <span class="font-weight-bold button"> {{ item.bidders_count }}</span>
        </v-btn>
      </template>

      <template v-slot:[`item.clarifications_count`]="{ item }">
        <v-btn
          class="mx-auto"
          :elevation="0"
          dark
          small
          rounded
          color="warning"
          @click="clarificationPreview(item)"
        >
          <span class="font-weight-bold button">
            {{
              item.clarifications_answered + "/" + item.clarifications_count
            }}</span
          >
        </v-btn>

        <!-- <a
          @click="viewClarification"
          class="info--text text--darken-1 text-decoration-underline"
        >
          <v-badge
            color="info"
            :content="item.clarifications_answered + '/' + item.clarifications"
          >
            <v-icon>mdi-message-alert</v-icon> View
          </v-badge>
        </a> -->
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TenderService from "../../services/tender.service";
import moment from "moment";

export default {
  components: {
    // UpdateProfile,
  },
  data: () => ({
    name: "MarketPlace",
    page: "Market",
    loading: true,
    search:null,
    selected: [0],
    files: "",
    docURI:
      "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
    tenderCategories: ["Open", "closed", "On Invitation"],
    remember: 0,
    timeout: 2000,
    dialog: false,
    countries: ["Kenya", "Uganda", "Tanzania", "Rwanda"],
    owners: [1, 2, 3, 4],

    breadcrumps: [
      {
        text: "Dashboard",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Marketplace",
        disabled: false,
        href: "breadcrumbs_link_1",
      },
      {
        text: "Open Tenders",
        disabled: true,
        href: "breadcrumbs_link_2",
      },
    ],
    valid: true,
    passwordRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    emailRules: [
      (v) => !!v || "Valid E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    headers: [
      {
        text: "Tender No",
        align: "start",
        filterable: true,
        value: "tender_no",
      },
      {
        text: "Tender Details",
        value: "title",
      },
      {
        text: "Category",
        value: "category.title",
      },
      {
        text: "Closing Date",
        value: "closing_date",
      },
      // {
      //   text: "Downloads",
      //   value: "documents",
      // },
      {
        text: "Status",
        value: "status",
      },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      company: {
        company_name: "",
      },
      email: "",
      phone: "",
    },
    defaultItem: {
      name: "",
      company: {
        company_name: "",
      },
      email: "",
      phone: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getAllTenders();
    this.getCategories();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCompanyName(company) {
      return company.company_name;
    },
    tenderPreview(item) {
      this.$store.dispatch('tender/setSelected', item);
      this.$router.push("/tender-preview/" + item.id);
    },
    getCategories() {
      return TenderService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.tenderCategories = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllTenders() {
      return TenderService.getAppliedTenders(this.user.id).then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
            this.loading = false;
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        TenderService.deleteUser(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.updateUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.storeUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>